.contest {
	margin-bottom: 80px;
	display: flex;
	flex: 1 0 auto;
	min-height: 1px;
	width: 100%;
	&__title {
		color: $blue;
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
	}
	&__date {
		color: #6f6f6f;
		font-size: 14px;
	}
	&__image-default__image {
		background: #eaeaea;
		padding-bottom: 83.4688%;
		position: relative;
		.contest__title {
			color: grey;
			left: 0;
			display: flex !important;
			justify-content: center;
			align-items: center;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
			font-size: 24px;
			padding: 20px;
			text-align: center;
		}
	}
	&__image {
		flex: 1 0 auto;
		height: auto;
		margin-bottom: 15px;
		min-height: 1px;
		div {
			width: 100%;
		}
		img {
			display: block;
			margin: auto;
		}
	}
	&__text-empty {
		color: $blue;
		font-size: 16px;
		margin-bottom: 40px;
	}
	&__winners {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		@media screen and (max-width: 991px) {
			margin-top: 15px;
		}
		&__title {
			color: $blue;
			font-size: 14px;
		}
	}
	.button {
		margin-top: 20px;
	}
}

.contest-container--running {
	.contest {
		flex-flow: column;
		&__content-container {
			flex-flow: column;
			display: flex;
			flex: 0 0 auto;
		}
		&__winners {
			display: none;
		}
		&__winners__title {
			display: none;
		}
	}
}

.contest-container--ended {
	.contest {
		@media screen and (max-width: 767px) {
			flex-flow: column;
		}
		&__title {
			display: inline-block;
			@media screen and (min-width: 768px) {
				vertical-align: middle;
			}
		}
		&__date {
			@media screen and (min-width: 768px) {
				display: inline-block;
				border-left: 2px solid $blue;
				padding-left: 10px;
				margin-left: 10px;
				vertical-align: middle;
			}
			margin-bottom: 10px;
		}
		&__content-container {
			width: 100%;
		}
		&__title-container {
			border-bottom: 1px solid #c2c2c2;
			margin-bottom: 30px;
		}
		&__image-default {
			flex: 0 1 auto;
			margin-right: 40px;
			width: 40%;
		}
		&__image {
			display: block;
			@media screen and (min-width: 768px) {
				flex: 0 1 auto;
				margin-right: 40px;
				width: 40%;
				img {
					margin-top: 0;
					margin-left: 0;
				}
			}
		}
	}
	.button {
		display: none;
	}
}
