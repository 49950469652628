@import "variables";

.contest-hub {
	@import "_contest";
	@import "_winner";
	padding: 30px 0 0;
	.page-title {
		border-bottom: 1px solid #c2c2c2;
		color: $blue;
		font-size: 30px;
		font-weight: bold;
		line-height: 1;
		padding-bottom: 20px;
		margin-bottom: 40px;
		svg {
			margin-right: 10px;
		}
		span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	.js-running-contest,
	.js-ended-contest {
		position: relative;
		.contest-container {
			opacity: 0;
			transition: opacity 500ms ease;
		}
		&.active {
			.contest-container {
				opacity: 1;
			}
		}
	}
}
