.winner {
	flex: 0 0 auto;
	margin-bottom: 20px;
	padding-right: 15px;
	width: 25%;
	@media screen and (max-width: 991px) {
		width: 50%;
		margin-bottom: 15px;
	}
	&__name {
		color: $blue;
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
		@media screen and (max-width: 991px) {
			margin-bottom: 5px;
		}
	}
	&__city {
		color: #6f6f6f;
		font-size: 14px;
	}
}
